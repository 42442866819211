exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-getting-there-tsx": () => import("./../../../src/pages/getting-there.tsx" /* webpackChunkName: "component---src-pages-getting-there-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-places-to-stay-tsx": () => import("./../../../src/pages/places-to-stay.tsx" /* webpackChunkName: "component---src-pages-places-to-stay-tsx" */),
  "component---src-pages-places-tsx": () => import("./../../../src/pages/places.tsx" /* webpackChunkName: "component---src-pages-places-tsx" */),
  "component---src-pages-registry-tsx": () => import("./../../../src/pages/registry.tsx" /* webpackChunkName: "component---src-pages-registry-tsx" */),
  "component---src-pages-schedule-tsx": () => import("./../../../src/pages/schedule.tsx" /* webpackChunkName: "component---src-pages-schedule-tsx" */),
  "component---src-pages-things-to-do-tsx": () => import("./../../../src/pages/things-to-do.tsx" /* webpackChunkName: "component---src-pages-things-to-do-tsx" */)
}

